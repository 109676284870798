<template>
  <q-page padding>
    <form>
      <!-- Details for booker only -->
      <q-card>
        <q-card-section class="bg-primary">
          {{ $t('ferry.passengers.booker') }}
        </q-card-section>

        <label>
          <m-phone-field
            :dialcode="booker.mobile_phone_dialcode"
            :phone="booker.mobile_phone_number"
            :borderless="$q.platform.is.mobile"
            @dialcode="val => booker.mobile_phone_dialcode = val"
            @phone="val => booker.mobile_phone_number = val"
          />
        </label>
        <i18n v-if="$v.booker.mobile_phone_dialcode.$error || $v.booker.mobile_phone_number.$error" path="validation.please_provide.default" class="error">
          <span>{{ $t('validation.please_provide.phone') }}</span>{{ $t('validation.please_provide.booker') }}
        </i18n>

        <label>
          {{ $t('email') }}
          <input v-model="booker.email" class="email">
        </label>
        <i18n v-if="$v.booker.email.$error" path="validation.please_provide.default" class="error">
          <span>{{ $t('validation.please_provide.email') }}</span>{{ $t('validation.please_provide.booker') }}
        </i18n>

        <label>
          {{ $tc('address') }}
          <input v-model="booker.address">
        </label>
        <i18n v-if="$v.booker.address.$error" path="validation.please_provide.default" class="error">
          <span>{{ $t('validation.please_provide.address') }}</span>{{ $t('validation.please_provide.booker') }}
        </i18n>

        <label>
          {{ $t('city') }}
          <input v-model="booker.city">
        </label>
        <i18n v-if="$v.booker.city.$error" path="validation.please_provide.default" class="error">
          <span>{{ $t('validation.please_provide.city') }}</span>{{ $t('validation.please_provide.booker') }}
        </i18n>

        <label>
          {{ $tc('county') }}
          <input v-model="booker.county">
        </label>
        <i18n v-if="$v.booker.county.$error" path="validation.please_provide.default" class="error">
          <span>{{ $t('validation.please_provide.county') }}</span>{{ $t('validation.please_provide.booker') }}
        </i18n>

        <label>
          {{ $tc('post_code') }}
          <input v-model="booker.post_code">
        </label>
        <i18n v-if="$v.booker.post_code.$error" path="validation.please_provide.default" class="error">
          <span>{{ $t('validation.please_provide.post_code') }}</span>{{ $t('validation.please_provide.booker') }}
        </i18n>

        <label>
          {{ $t('title') }}
          <input v-model="booker.title" class="title">
        </label>
        <i18n v-if="$v.booker.title.$error" path="validation.please_provide.default" class="error">
          <span>{{ $t('validation.please_provide.title') }}</span>{{ $t('validation.please_provide.booker') }}
        </i18n>

        <label>
          {{ $t('name.forename') }}
          <input v-model="booker.first_name" class="first-name">
        </label>
        <i18n v-if="$v.booker.first_name.$error" path="validation.please_provide.default" class="error">
          <span>{{ $t('validation.please_provide.first_name') }}</span>{{ $t('validation.please_provide.booker') }}
        </i18n>

        <label>
          {{ $t('name.surname') }}
          <input v-model="booker.last_name" class="last-name">
        </label>
        <i18n v-if="$v.booker.last_name.$error" path="validation.please_provide.default" class="error">
          <span>{{ $t('validation.please_provide.last_name') }}</span>{{ $t('validation.please_provide.booker') }}
        </i18n>

        <country-select
          :country="booker.country"
          :query="countrySearch"
          @selected="val => { booker.country = val; booker.country_code = val.id }"
        />
        <i18n v-if="$v.booker.country_code.$error" path="validation.please_provide.default" class="error">
          <span>{{ $t('validation.please_provide.country_code') }}</span>{{ $t('validation.please_provide.booker') }}
        </i18n>

        <label class="checkbox">
          {{ $t('ferry.passengers.booker_passenger_one') }}
          <input v-model="bookerP1" type="checkbox">
        </label>
      </q-card>

      <!-- Details for each other passenger -->
      <q-card v-for="(passenger, key) in passengers" :key="key" class="passenger-form" square>
        <q-card-section class="bg-primary">
          {{ `Passenger ${key.slice(1)}` }}
        </q-card-section>
        <label>
          {{ $t('title') }}
          <input
            v-model="passenger.title"
            class="title"
            :disabled="key === 'P1' && bookerP1"
          >
        </label>
        <i18n v-if="$v.passengers[key].title.$error" path="validation.please_provide.default" class="error">
          <span>{{ $t('validation.please_provide.title') }}</span>{{ $t('passenger_x', {n: key.slice(1)}) }}
        </i18n>

        <label>
          {{ $t('name.forename') }}
          <input
            v-model="passenger.first_name"
            class="first-name"
            :disabled="key === 'P1' && bookerP1"
          >
        </label>
        <i18n v-if="$v.passengers[key].first_name.$error" path="validation.please_provide.default" class="error">
          <span>{{ $t('validation.please_provide.first_name') }}</span>{{ $t('passenger_x', {n: key.slice(1)}) }}
        </i18n>

        <label>
          {{ $t('name.surname') }}
          <input
            v-model="passenger.last_name"
            class="last-name"
            :disabled="key === 'P1' && bookerP1"
          >
        </label>
        <i18n v-if="$v.passengers[key].last_name.$error" path="validation.please_provide.default" class="error">
          <span>{{ $t('validation.please_provide.last_name') }}</span>{{ $t('passenger_x', {n: key.slice(1)}) }}
        </i18n>

        <div class="gender">
          {{ $t('ferry.passengers.gender') }}
          <label>
            {{ $t('abbreviation.male') }}
            <input v-model="passenger.gender" type="radio" :name="`${key}-gender`" value="M">
          </label>
          <label>
            {{ $t('abbreviation.female') }}
            <input v-model="passenger.gender" type="radio" :name="`${key}-gender`" value="F">
          </label>
        </div>
        <i18n v-if="$v.passengers[key].gender.$error" path="validation.please_provide.default" class="error">
          <span>{{ $t('validation.please_provide.gender') }}</span>{{ $t('passenger_x', {n: key.slice(1)}) }}
        </i18n>

        <country-select
          :country="passenger.country"
          :query="countrySearch"
          :disabled="key === 'P1' && bookerP1"
          @selected="val => { passenger.country = val; passenger.country_code = val.id }"
        />
        <i18n v-if="$v.passengers[key].country_code.$error" path="validation.please_provide.default" class="error">
          <span>{{ $t('validation.please_provide.country_code') }}</span>{{ $t('passenger_x', {n: key.slice(1)}) }}
        </i18n>
      </q-card>

      <!-- Submit button -->
      <div class="cta">
        <m-cta-button :label="$t('submit')" @ctaClick="submit" />
      </div>
    </form>
  </q-page>
</template>

<script>
import 'styles/ondemand.styl'
import loading from 'utils/loading'
import store from 'store'
import i18n from 'i18n'
import { Notify } from 'quasar'
import { handleErrors } from 'utils/utils'
import { passengers as getPassengers, accommodation, countries } from 'api/ferry'
import { required } from 'vuelidate/lib/validators'
import { MPhoneField, MCtaButton } from 'components/'
import CountrySelect from './country-select'

export default {
  components: { CountrySelect, MPhoneField, MCtaButton },
  data () {
    return {
      passengers: { },
      booker: { },
      bookerP1: false
    }
  },
  watch: {
    booker: {
      deep: true,
      handler ({ title, first_name, last_name, country, country_code }) {
        if (!this.bookerP1) return
        this.passengers.P1 = {
          ...this.passengers.P1,
          title,
          first_name,
          last_name,
          country,
          country_code
        }
      }
    },
    bookerP1 (val) {
      if (val) {
        const { title, first_name, last_name, country, country_code } = this.booker
        this.passengers.P1 = {
          ...this.passengers.P1,
          title,
          first_name,
          last_name,
          country,
          country_code
        }
      } else {
        this.passengers.P1 = {
          id: 'P1',
          title: null,
          first_name: null,
          last_name: null,
          country: {},
          country_code: null,
          gender: this.passengers.P1.gender
        }
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    loading.start({
      message: i18n.t('loading.checking.your_details'),
      partnerSlug: store.getters.partner.slug,
      spinnerColor: store.getters.partner.hex
    })
    next(async vm => {
      const { results, return_service_token, outbound_service_token } = vm.$store.getters['ondemand/stash']
      const params = { return_service_token, outbound_service_token }

      try {
        await accommodation(results.search_token, params)
        const { data } = await getPassengers(results.search_token, { accommodations: { outbound: [], return: [] } })
        vm.passengers = data.required_details.passengers
        vm.booker = data.required_details.booker

        if (!vm.booker.mobile_phone_dialcode) vm.booker.mobile_phone_dialcode = '44'
      } catch (err) {
        if (err.errors) {
          if (Object.keys(err.errors).includes('search_token')) {
            Notify.create({ message: i18n.t('error.search_token_expired') })
            next({ name: 'ondemand-ferry' })
          }
        } else {
          handleErrors(err)
          next(from)
        }
      } finally {
        loading.stop()
      }
    })
  },
  validations () {
    const validations = {
      passengers: {},
      booker: {
        title: { required },
        first_name: { required },
        last_name: { required },
        email: { required },
        mobile_phone_number: { required },
        mobile_phone_dialcode: { required },
        address: { required },
        city: { required },
        county: { required },
        country_code: { required },
        post_code: { required }
      }
    }
    for (const key in this.passengers) {
      validations.passengers[key] = {
        title: { required },
        first_name: { required },
        last_name: { required },
        gender: { required },
        country_code: { required }
      }
    }

    return validations
  },
  methods: {
    async countrySearch (val) {
      const results = await countries({ query: val })
      return results.data
    },
    submit () {
      this.$v.$touch()
      if (this.$v.$error) return
      this.$store.dispatch('ondemand/stash', { passengers: { passengers: this.passengers, booker: this.booker } })
      this.$router.push({ name: 'ondemand-ferry-summary' })
    }
  }
}
</script>

<style lang="stylus" scoped>
form
  background white
  display flex
  flex-flow column
  min-height calc(100vh - 64px)
  @media (min-width: 768px)
    flex-flow row wrap
    justify-content space-around

.passenger-form
  display flex
  flex-direction column
  background white

.q-card
  width 80vw
  max-width 600px
  @media (max-width: 768px)
    width 100%
    margin 8px 0
    box-shadow none

label, .gender
  display flex
  flex-direction column
  padding 8px 16px
  margin 10px 10px 0 10px
  border solid rgba(0,0,0,0.2) 1px
  border-radius 25px !important
  font-size 75%
  letter-spacing 0.00937em
  @media (min-width: 768px)
    border none
    input
      border-bottom solid rgba(0,0,0,0.2) 1px
  &.q-select
    font-size 16px
    margin 0 5px 0 0
    padding 0
    border none

.gender
  flex-direction row
  align-items start
  justify-content space-between
  label
    padding 0
    margin 0
    flex-direction row
    align-items center
    font-size 100%
    border none

label.checkbox
  flex-direction row
  border none
  align-items center

input
  &[type="radio"], &[type="checkbox"]
    margin 0 12px
    width: 20px;
    height: 20px;
    border: 2px solid #666
  &[type="radio"]
    border-radius 15px
    &:checked
      border: 2px solid var(--q-color-primary)
      &:after
        width: 14px;
        height: 14px;
        bottom 2px
        left 1px
        border-radius: 15px;
        position: relative;
        background-color: var(--q-color-primary)
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white
  &[type="checkbox"]
    &:checked
      padding 1px 0
      line-height 14px
      &:after
        content '\2714'
        font-size 140%

.phone-label
  display flex
  flex-direction row

select
  border none
  background none
  font-size 16px
  margin-left -3px

@keyframes shake {
  0% {
    transform translateX(0)
  }
  25% {
    transform translateX(-2px)
  }
  50% {
    transform translateX(0)
  }
  75% {
    transform translateX(2px)
  }
  100% {
    transform translateX(0)
  }
}

.error
  color red
  padding 0 16px
  font-size 80%
  animation shake 0.1s 2

.submit-footer
  width 100vw
  padding 12px
  margin-top 18px
  display flex
  justify-content center
  position sticky
  bottom 14px
  background white

.submit-button
  width 100%
  max-width 600px
  color white
  background-color var(--q-color-primary)
  border none
  text-transform uppercase
  font-weight 500
  border-radius 10px
  padding 8px 16px
  align-self flex-end
</style>
