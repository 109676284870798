<template>
  <div>
    <label>
      {{ $t('country') }}
      <input
        v-model="searchText"
        :disabled="disabled"
        @input="searchChanged"
        @focus="showCountriesList = true"
      >
    </label>

    <m-dropdown-transition
      :collapsed="!showCountriesList"
      class="list"
    >
      <div v-for="c in countries" :key="c.id" @click="select(c)">
        {{ c.name }}
      </div>
    </m-dropdown-transition>
  </div>
</template>

<script>
import _ from 'lodash'
import { MDropdownTransition } from 'components/'

export default {
  components: { MDropdownTransition },
  props: {
    query: Function,
    country: {
      type: Object,
      default: () => ({ name: '', id: '' })
    },
    disabled: Boolean
  },
  data () {
    return {
      showCountriesList: false,
      countries: [],
      text: ''
    }
  },
  computed: {
    searchText: {
      get () {
        return this.text
      },
      set (val) {
        this.text = val
      }
    }
  },
  watch: {
    country: {
      immediate: true,
      deep: true,
      handler (val) {
        this.searchText = val.name
      }
    }
  },
  methods: {
    async search (val) {
      const res = await this.query(val)
      this.countries = res
    },
    select (val) {
      this.countries = []
      this.showCountriesList = false
      this.$emit('selected', val)
      this.searchText = val.name
    },
    lookup: _.debounce(function () {
      if (!this.searchText) {
        this.$emit('selected', {})
        this.countries = []
        this.showCountriesList = false
        return
      }
      if (!this.showCountriesList) this.showCountriesList = true

      return this.search(this.searchText)
    }, 300),
    searchChanged () {
      this.lookup()
    }
  }
}
</script>

<style lang="stylus" scoped>
label
  display flex
  flex-direction column
  padding 8px 16px
  margin 10px 10px 0 10px
  border solid rgba(0,0,0,0.2) 1px
  border-radius 25px
  font-size 75%
  letter-spacing 0.00937em
  @media (min-width: 768px)
    border none
    input
      border-bottom solid rgba(0,0,0,0.2) 1px
  &.q-select
    font-size 16px
    margin 0 5px 0 0
    padding 0
    border none

.list
  position absolute
  left 5%
  right 5%
  background white
  z-index 1
  box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12);
  border-radius 5px
  div
    padding 5px 10px
    &:active
      background var(--q-color-tint)

</style>
