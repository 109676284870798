import { render, staticRenderFns } from "./results.vue?vue&type=template&id=47d6bc32&scoped=true&"
import script from "./results.vue?vue&type=script&lang=js&"
export * from "./results.vue?vue&type=script&lang=js&"
import style0 from "./results.vue?vue&type=style&index=0&id=47d6bc32&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47d6bc32",
  null
  
)

export default component.exports